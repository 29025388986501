import { useEffect, useState } from 'react';
import SidebarMesages from '~/components/PersonalCoverage/SidebarMesages';
import { Container, Card, Grid, Box, CardContent, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import * as styles from './styles';
import Input from '~/components/Input';
import InputDate from '~/components/InputDate';
import useStore, { storeState } from '~/store';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import schema from './validation';
import Header from '~/components/Header';

type Inputs = {
  fullName: string;
  email: string;
  phone: string;
  documentNumber: string;
  birthDate: string;
};

export default function HelpZipcodePage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const planParam = searchParams.get('plan');
  const distribuitorParam = searchParams.get('dis');
  const {
    quoteResponse,
    quoteAsyncPostCustomer,
    quote,
    quotes,
    setPhoneNumber,
    setCpf,
    setBirthDate,
    setEmail,
    setName
  } = useStore((store) => ({
    quoteAsyncPostCustomer: store.quoteAsyncPostCustomer,
    quote: store.quote,
    quotes: store.quotes,
    quoteResponse: store.quoteResponse,
    setPhoneNumber: store.SetPhoneNumber,
    setCpf: store.SetCpf,
    setBirthDate: store.SetBirthDate,
    setEmail: store.SetEmail,
    setName: store.SetName
  }));

  const {
    handleSubmit,
    control,
    getValues,
    trigger,
    watch,
    formState: { errors, dirtyFields },
    setValue
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      fullName: quote?.name,
      email: quote?.email,
      phone: quote?.phoneNumber,
      documentNumber: quote?.cpf,
      birthDate: quote?.birthDate
    }
  });
  const [loading, setLoading] = useState(false);

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    setLoading(true);
    try {
      await quoteAsyncPostCustomer(quoteResponse?.quote_id, {
        name: data.fullName,
        email: data.email,
        mobile_phone: data.phone,
        document: data.documentNumber,
        document_type: 1,
        birthDate: data.birthDate
      });

      setLoading(false);
      setEmail(getValues('email'));
      setName(getValues('fullName'));
      setPhoneNumber(getValues('phone'));
      setCpf(getValues('documentNumber'));
      setBirthDate(getValues('birthDate'));
      navigate(`/payment/?plan=${planParam}&dis=${distribuitorParam}`);
    } catch (error: any) {
      setLoading(false);
      let errorMessage =
        'Não foi possível realizar a sua assinatura no momento. Por favor, tente novamente mais tarde.';
      if (error.isAxiosError) {
        const notifications = error.response?.data?.notifications;
        if (notifications) errorMessage = notifications[0]?.message;
      }
      storeState.addToast({
        title: 'Error',
        message: errorMessage,
        type: 'error'
      });
    }
  };

  useEffect(() => {
    if (quotes.length === 0) {
      navigate(`/?modal=true&plan=${planParam}&dis=${distribuitorParam}`);
    }
  }, []);

  useEffect(() => {
    watch((_data, { name, type }) => {
      if (name === 'documentNumber' && type === 'change') {
        trigger('documentNumber');
      }
    });
  }, [watch]);

  return (
    <Container maxWidth="lg" sx={styles.container}>
      <Grid container spacing={2}>
        <Grid item md={8} xs={12}>
          <Card>
            <CardContent sx={{ padding: { sm: 3 } }}>
              <Header
                title="Vamos finalizar a sua proposta?"
                goBackTo={`/personalCoverage/?plan=${planParam}&dis=${distribuitorParam}`}
              />
              <Box sx={styles.descriptionContainer}>
                <Typography variant="body1">
                  Não se preocupe, os dados sobre o ciclista e o equipamento serão solicitados logo após esta etapa de
                  pagamento!
                </Typography>
              </Box>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box sx={styles.containerForm}>
                  <Controller
                    name="fullName"
                    control={control}
                    render={({ field }) => (
                      <>
                        <Input
                          label="Nome Completo"
                          placeholder="Seu nome completo"
                          error={errors?.fullName ? true : false}
                          isDirty={dirtyFields.fullName}
                          helperText={errors?.fullName?.message}
                          {...field}
                        />
                      </>
                    )}
                  />
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                      <>
                        <Input
                          label="Seu email"
                          type="email"
                          placeholder="seuemail@email.com"
                          error={errors?.email ? true : false}
                          isDirty={dirtyFields.email}
                          helperText={errors?.email?.message}
                          {...field}
                        />
                      </>
                    )}
                  />
                  <Controller
                    name="phone"
                    control={control}
                    render={({ field }) => (
                      <>
                        <Input
                          label="Celular"
                          placeholder="(XX) X XXXX-XXXX"
                          mask={'phone'}
                          isDirty={dirtyFields.phone}
                          error={errors?.phone?.message ? true : false}
                          helperText={errors?.phone?.message}
                          maxRows={1}
                          {...field}
                        />
                      </>
                    )}
                  />
                  <Controller
                    name="documentNumber"
                    control={control}
                    render={({ field }) => (
                      <>
                        <Input
                          label="CPF"
                          mask={'cpf'}
                          isDirty={dirtyFields.documentNumber}
                          error={errors?.documentNumber?.message ? true : false}
                          helperText={errors?.documentNumber?.message}
                          placeholder="XXX.XXX.XXX-XX"
                          {...field}
                        />
                      </>
                    )}
                  />
                  <Controller
                    name="birthDate"
                    control={control}
                    render={({ field }) => (
                      <>
                        <InputDate
                          label="Data de nascimento"
                          placeholder="XX/XX/XXXX"
                          defaultValue={quote?.birthDate ? new Date(quote?.birthDate) : undefined}
                          isDirty={dirtyFields.birthDate}
                          error={errors?.birthDate?.message ? true : false}
                          helperText={errors?.birthDate?.message}
                          setField={(value) => {
                            setValue('birthDate', value);
                          }}
                          {...field}
                        />
                      </>
                    )}
                  />
                </Box>
                <Box sx={styles.containerButton}>
                  <LoadingButton variant="contained" type="submit" loading={loading}>
                    Vamos lá
                  </LoadingButton>
                </Box>
              </form>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          md={4}
          xs={12}
          sx={{
            display: {
              xs: 'none',
              lg: 'block'
            }
          }}
        >
          <Card>
            <CardContent>
              <SidebarMesages />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
